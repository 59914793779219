import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import MetaWp from "../components/meta-wp"
import Layout from "../components/layout"
import PostBlock from "../components/post-block"
import ContentGrid from "../components/content-grid"
import ButtonBlock from "../components/button-block"

const DefaultPostTemplate = ({ data }) => {
  const {
    wp,
    allPosts,
    metaWp,
    site: { siteMetadata },
    wordpressAcfOptions: { globalMeta },
  } = data

  const allPostsExceptCurrent = allPosts.edges.filter(({ node }) => {
    return node.wordpressId === wp.wordpressId ? null : node
  })

  return (
    <Layout>
      <MetaWp {...metaWp} global={globalMeta} defaults={siteMetadata} />

      <PostBlock {...wp} />

      <ContentGrid
        published={true}
        type={`listing`}
        contentType={[`post`]}
        allPages={{}}
        allPosts={{ edges: allPostsExceptCurrent }}
        categories={[]}
        numberOfItems={`6`}
        title={`Senaste Artiklar`}
        subTitle={``}
        backgroundColor={`white`}
        tags={[]}
        showCategories={true}
        showExcerpts={true}
        showReadMore={true}
        showImages={true}
        showTitles={true}
        sort={`published_asc`}
      />

      <ButtonBlock
        published={true}
        layout={`center`}
        icon={`arrow-right`}
        color={`green`}
        link={{
          title: `Till Artiklar`,
          url: `/artiklar/`,
          target: ``,
        }}
      />
    </Layout>
  )
}

DefaultPostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default DefaultPostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    site {
      ...SiteMetaData
    }

    wordpressAcfOptions {
      ...GlobalMetaOptions
    }

    ...MetaWpPost

    ...AllPostsFragment

    wp: wordpressPost(id: { eq: $id }) {
      ...PostDefaultFragment
    }
  }
`
