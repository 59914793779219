import React from "react"
import PropTypes from "prop-types"
import { rem } from "polished"
import styled from "styled-components"
import Img from "gatsby-image"

import Link from "../link"

const Container = styled.section`
  ${(props) => props.theme.gridContainer()};

  color: ${(props) => props.theme.colorBlueDark};
`

const Inner = styled.div`
  max-width: ${rem(768)};
  padding-top: ${rem(60)};
  padding-bottom: ${rem(60)};

  @media ${(props) => props.theme.smallDown} {
    padding-top: ${rem(30)};
    padding-bottom: ${rem(30)};
  }
`

const PostImage = styled(Img)`
  margin-bottom: ${rem(30)};
`

const Introduction = styled.div.attrs({ className: `styled` })`
  margin-top: ${rem(20)};

  > p {
    font-size: ${rem(24)} !important;
    line-height: 1.5;

    @media ${(props) => props.theme.xxlargeDown} {
      font-size: ${rem(22)} !important;
    }
    @media ${(props) => props.theme.mediumDown} {
      font-size: ${rem(20)} !important;
    }
    @media ${(props) => props.theme.smallDown} {
      font-size: ${rem(18)} !important;
    }
  }
`

const Content = styled.div.attrs({ className: `styled` })`
  margin-top: ${rem(24)};
`

const Date = styled.div`
  margin: ${rem(10)} 0;
`

const Meta = styled.div`
  margin: ${rem(20)} 0;
`

const Categories = styled.div`
  margin: ${rem(10)} 0;
  display: flex;
`

const CategoriesTitle = styled.div`
  font-weight: 500;
  margin-right: ${rem(5)};
`

const Category = styled.div`
  margin-right: ${rem(5)};

  a {
    text-decoration: underline;
  }
`

const PostBlock = ({ title, body, date, categories, tags, acf }) => {
  return (
    <Container>
      <Inner>
        {acf.image && (
          <PostImage
            fluid={acf.image.localFile.childImageSharp.fluid}
            alt={acf.image.altText}
          />
        )}

        <h1
          className={`styled-h1`}
          dangerouslySetInnerHTML={{ __html: title }}
        />

        <Date>{date}</Date>

        {acf.intro && (
          <Introduction dangerouslySetInnerHTML={{ __html: acf.intro }} />
        )}

        {body && <Content dangerouslySetInnerHTML={{ __html: body }} />}

        <Meta>
          {categories && categories.length > 0 && (
            <Categories>
              <CategoriesTitle>Kategori:</CategoriesTitle>
              {categories.map((category, index) => (
                <Category key={category.id}>
                  <Link to={`/kategori/${category.slug}`}>
                    {category.name}
                    {index !== categories.length - 1 && `,`}
                  </Link>
                </Category>
              ))}
            </Categories>
          )}

          {tags && tags.length > 0 && (
            <Categories>
              <CategoriesTitle>Tagg:</CategoriesTitle>
              {tags.map((tag, index) => (
                <Category key={tag.id}>
                  <Link to={`/tagg/${tag.slug}`}>
                    {tag.name}
                    {index !== tags.length - 1 && `,`}
                  </Link>
                </Category>
              ))}
            </Categories>
          )}
        </Meta>
      </Inner>
    </Container>
  )
}

PostBlock.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string,
  date: PropTypes.string,
  categories: PropTypes.array,
  tags: PropTypes.array,
  acf: PropTypes.object,
}

export default PostBlock
